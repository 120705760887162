import { createRouter, createWebHistory } from 'vue-router';

import { getToken } from '@/services/api/admin-auth';

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('./views/RegistrationScreen.vue'),
      props: (route) => ({ defaultEntryCode: route.query['entry-code'] }),
    },
    {
      path: '/challenge-details/:token',
      name: 'challenge details',
      component: () => import('./views/ChallengeInformation.vue'),
      props: true,
    },
    {
      path: '/complete-information/:token',
      name: 'complete information',
      component: () => import('./views/CompleteInformation.vue'),
      props: true,
    },
    {
      path: '/admin/sign-in',
      name: 'sign-in',
      component: () => import('./views/SignIn.vue'),
    },
    {
      path: '/admin/login',
      name: 'login',
      component: () => import('./views/AdminLogin.vue'),
    },
    {
      path: '/admin',
      component: () => import('./views/AdminRoot.vue'),
      children: [
        {
          path: '',
          name: 'admin',
          redirect: '/admin/applicants',
        },
        {
          path: 'applicants',
          name: 'applicants',
          component: () => import('./views/ApplicantList.vue'),
        },
        {
          path: 'templates',
          name: 'templates',
          component: () => import('./views/TemplateList.vue'),
        },
        {
          path: 'entry-codes',
          name: 'entry-codes',
          children: [
            {
              path: '',
              name: 'list-entry-codes',
              component: () => import('./views/AdminEntryCodesList.vue'),
            },
            {
              path: 'create',
              name: 'create-entry-code',
              component: () => import('./views/AdminEntryCodesCreate.vue'),
            },
            {
              path: ':tag',
              name: 'edit-entry-code',
              component: () => import('./views/AdminEntryCodesEdit.vue'),
            },
          ],
        },
        {
          path: 'batches',
          name: 'batches',
          children: [
            {
              path: '',
              name: 'list-batches',
              component: () => import('./views/AdminBatchList.vue'),
            },
            {
              path: 'create',
              name: 'create-batches',
              component: () => import('./views/AdminBatchCreate.vue'),
            },
            {
              path: ':id',
              name: 'edit-batches',
              component: () => import('./views/AdminBatchEdit.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('./views/NotFound.vue'),
    },
  ],
});

router.beforeEach(async (to) => {
  if (to.path.startsWith('/admin') && to.path !== '/admin/login') {
    // Ensure that the user is authenticated before accessing the admin panel.
    // For efficiency, `getToken` is only called if the user tries to access
    // the admin route.
    if (!(await getToken())) {
      return '/admin/login';
    }
  }

  // If the user is already authenticated, prevent them from accessing the login screen.
  // Attempting to sign in again after already being signed in will result in
  // `UserAlreadyAuthenticated` exception and we don't want that.
  if (to.path === '/admin/login') {
    if (await getToken()) {
      return '/admin/applicants';
    }
  }
});
